import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import i18n from "@/libs/i18n";

export default function usePostsList() {
  const refAgencyListTable = ref(null);

  const tableColumns = [
    { key: "index", label: "#" },
    { key: "username", label: i18n.t("Agency Name") },
    { key: "code", label: i18n.t("Agency Code") },
    { key: "aff_refferal", label: i18n.t("Mã đại lý cha") },
    { key: "email", label: i18n.t("Email") },
    // { key: "password", label: i18n.t("Password") },
    { key: "phone", label: i18n.t("Phone") },
    { key: "status", label: i18n.t("Status") },
    { key: "discount", label: i18n.t("Discount_agency") },
    { key: "user_count", label: i18n.t("User Count") },
    { key: "debt_previous", label: i18n.t("Dư nợ") },
    { key: "social_network", label: i18n.t("Mạng xã hội") },
    { key: "social_network_contact", label: i18n.t("Thông tin mạng xã hội") },
    { key: "created_by", label: i18n.t("Created by") },
    { key: "created_at", label: i18n.t("Created at") },
    { key: "updated_at", label: i18n.t("Updated at") },
    { key: "updated_by", label: i18n.t("Updated by") },
    { key: "action", label: i18n.t("Actions") },
  ];
  const perPage = ref(25);
  const totalAgency = ref(0);
  const currentPage = ref(1);
  const search = ref({
    username: "",
    code: "",
    email: "",
    phone: "",
    status: "",
    fromDate: "",
    toDate: "",
    commissionType: "",
    agencyType: ""
  });
  const listAgency = ref([]);
  const listSocialNetwork = ref([])

  const refetchData = () => {
    fetchAgency();
  };

  watch([currentPage], () => {
    refetchData();
  });

  const getListSocialNetwork = (ctx, callback) => {
    store
      .dispatch("marketing/fetchListSocialNetwork")
      .then((response) => {
        if (response.data.code == "00") {
          listSocialNetwork.value = response.data.data;
        }
      });
  };

  getListSocialNetwork();

  const fetchAgency = (ctx, callback) => {
    store
      .dispatch("marketing/fetchAgency", {
        page: currentPage.value,
        username: search.value.username,
        code: search.value.code,
        email: search.value.email,
        phone: search.value.phone,
        status: search.value.status,
        fromDate: search.value.fromDate,
        toDate: search.value.toDate,
        commission_type: search.value.commissionType,
        agency_type: search.value.agencyType
      })
      .then((response) => {
        if (response.data.code == "00") {
          listAgency.value = response.data.data.map(item => {
            return {
              ...item,
              social_network: listSocialNetwork.value ? listSocialNetwork.value.filter((x) => x.id === item.social_network_id)[0]?.name : item.social_network_id
            }
          })
          totalAgency.value = response.data.count;
          perPage.value =
            currentPage.value == 1 ? response.data.data.length : 25;
        }
      });
  };

  fetchAgency();

  const dataMeta = computed(() => {
    const localItemsCount = refAgencyListTable.value
      ? refAgencyListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAgency.value,
    };
  });

  const resolveStatus = (status) => {
    if (status === 1) return { label: i18n.t("Active"), variant: "success" };
    if (status === 2) return { label: i18n.t("Inactive"), variant: "danger" };
    if (status === 3) return { label: i18n.t("Pending"), variant: "warning" };
  };


  return {
    search,
    fetchAgency,
    listAgency,
    tableColumns,
    perPage,
    currentPage,
    totalAgency,
    dataMeta,
    refAgencyListTable,
    refetchData,
    resolveStatus,
    listSocialNetwork
  };
}
