<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="2" lg="2" sm="6">
            <b-form-group label="Username" label-for="agency" class="mb-2">
              <b-form-input
                  id="username"
                  v-model="search.username"
                  class="form-control"
                  placeholder="Please Enter Agency"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="2" lg="2" sm="6">
            <b-form-group label="Code" label-for="code" class="mb-2">
              <b-form-input
                  id="code"
                  v-model="search.code"
                  class="form-control"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="2" lg="2" sm="6">
            <b-form-group label="Email" label-for="email" class="mb-2">
              <b-form-input
                  id="email"
                  v-model="search.email"
                  class="form-control"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="2" lg="2" sm="6">
            <b-form-group label="Phone" label-for="phone" class="mb-2">
              <b-form-input
                  id="code"
                  v-model="search.phone"
                  class="form-control"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="2" lg="2" sm="6">
            <b-form-group label="Status" label-for="status" class="mb-2">
              <v-select
                v-model="search.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :options="statusOptions"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="2" lg="2" sm="6">
            <b-form-group label="Loại hoa hồng" label-for="commissionType" class="mb-2">
              <v-select
                v-model="search.commissionType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :options="commissionTypeOptions"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="2" lg="2" sm="6">
            <b-form-group label="Loại đại lý" label-for="agencyType" class="mb-2">
              <v-select
                v-model="search.agencyType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :options="agencyTypeOptions"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="2" lg="2" sm="6" class="mb-2 ipad">
            <b-form-group :label="$t('From date')" label-for="fromDate">
              <flat-pickr
                id="fromDate"
                v-model="search.fromDate"
                class="form-control"
                placeholder="YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="2" lg="2" sm="6" class="mb-2 ipad">
            <b-form-group :label="$t('To date')" label-for="toDate">
              <flat-pickr
                id="toDate"
                v-model="search.toDate"
                class="form-control"
                placeholder="YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>
          
        </b-row>

        <b-row class="d-flex align-items-end justify-content-end">
          <b-col
              cols="12"
              md="4"
              class="d-flex align-items-end justify-content-end"
          >
            <b-button variant="outline-primary" @click="resetFilter()">
              Reset
            </b-button>
            <b-button variant="primary" class="ml-2" @click="searchFilter()">
              Search
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body class="mb-0">
      <div class="p-1">
        <b-row>
          <b-col
            v-if="$can('create', 'agency')"
            class="col d-flex justify-content-end"
          >
            <b-button variant="primary" @click="addAgency">
              <feather-icon icon="PlusIcon" size="16" /> {{ $t("New") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refAgencyListTable"
        class="position-relative table-white-space"
        :items="listAgency"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>
        <template #cell(username)="data">
          <!-- @click="handleUpdateAgency(data.item)" -->
          <b-link
            class="cursor-pointer text-primary"
            v-if="$can('read', 'agencydetail')"
            :to="{
              name: 'agency-detail',
              params: { id: data.item.id },
            }"
          >
            {{ data.item.username }}
          </b-link>
        </template>

        <template #cell(email)="data">
          {{ data.item.email }}
        </template>
        <template #cell(code)="data">
          {{ data.item.code }}
        </template>
        <template #cell(user_count)="data">
          {{ data.item.users_count }}
        </template>

        <template #cell(phone)="data">
          {{ data.item.phone }}
        </template>

        <template #cell(password)="data">
          {{ data.item.password }}
        </template>

        <template #cell(discount)="data">
          <span v-if="data.item.commission_type === 1" >
            Flexible
          </span>
          <span v-else>
            Fixed ({{ data.item.discount }}%) 
          </span>
        </template>

        <template #cell(debt_previous)="data">
          {{ data.item.debt_previous ? numberFormat(data.item.debt_previous / 1000) : '' }}
        </template>

        <template #cell(status)="data">
          <div :class="`text-capitalize align-items-center d-flex`">
            <span
              :class="`dot bg-${resolveStatus(data.item.status).variant}`"
            />{{ resolveStatus(data.item.status).label }}
          </div>
        </template>

        <template #cell(created_at)="data">
          {{
            data.item.created_at
              ? formatDateTime(data.item.created_at).replace(".000000Z", "")
              : ""
          }}
        </template>

        <template #cell(updated_at)="data">
          {{
            data.item.updated_at
              ? formatDateTime(data.item.updated_at).replace(".000000Z", "")
              : ""
          }}
        </template>

        <template #cell(action)="data">
          <b-button
            v-b-tooltip.hover.v-info
            :title="$t('Edit')"
            v-if="$can('update', 'agency')"
            variant="primary"
            class="btn-icon btn-sm mr-50"
            @click="handleUpdateAgency(data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <!-- <b-button
            v-b-tooltip.hover.v-danger
            :title="$t('Reject')"
            v-if="$can('view', 'infodelete')"
            variant="danger"
            class="btn-icon btn-sm"
            @click="deleteAgency(data.item.id)"
          >
            <feather-icon icon="XIcon" />
          </b-button> -->
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAgency"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Add Agency Modal -->
    <add-agency-modal @refetch-data="refetchData" />

    <!-- Update Agency Modal -->
    <update-agency-modal
      :agency-detail.sync="agencyDetail"
      :agencyDetail="agencyDetail"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { numberFormat, formatDateTime } from "@core/utils/filter";
import useAgencyList from "@/views/marketing/useAgencyList";
import marketingStoreModule from "@/views/marketing/marketingStoreModule";
import baseUrl from "@/libs/base-url";
import flatPickr from "vue-flatpickr-component";
import vSelect from 'vue-select';
import i18n from '@/libs/i18n'

export default {
  components: {
    AddAgencyModal: () => import("./AddAgencyModal.vue"),
    UpdateAgencyModal: () => import("./UpdateAgencyModal.vue"),
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    flatPickr,
    vSelect
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      agencyDetail: null,
    };
  },
  methods: {
    addAgency() {
      this.$bvModal.show("modal-add-agency");
    },
    // deleteAgency(agencyId) {
    //   this.$swal({
    //     title: "Are you sure ?",
    //     text: "You won't be able to revert this!",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonText: "Yes, delete it!",
    //     customClass: {
    //       confirmButton: "btn btn-primary",
    //       cancelButton: "btn btn-outline-danger ml-1",
    //     },
    //     buttonsStyling: false,
    //   }).then((result) => {
    //     if (result.value) {
    //       const agencyData = {
    //         id: agencyId,
    //       };
    //       store
    //         .dispatch("marketing/deleteAgency", agencyData)
    //         .then((response) => {
    //           if (response.data.code == "00") {
    //             this.$swal({
    //               icon: "success",
    //               title: "Deleted!",
    //               text: "Your item has been deleted.",
    //               customClass: {
    //                 confirmButton: "btn btn-success",
    //               },
    //             });
    //             this.refetchData();
    //           }
    //         });
    //     }
    //   });
    // },

    handleUpdateAgency(datas) {
      this.agencyDetail = datas;
      this.$bvModal.show("modal-update-agency");
    },
  },
  setup() {
    const MARKETING_MODULE_NAME = "marketing";
    if (!store.hasModule(MARKETING_MODULE_NAME))
      store.registerModule(MARKETING_MODULE_NAME, marketingStoreModule);
    onUnmounted(() => {
      if (store.hasModule(MARKETING_MODULE_NAME))
        store.unregisterModule(MARKETING_MODULE_NAME);
    });

    const statusOptions = [
			{ label: i18n.t('Active'), value: 1 },
			{ label: i18n.t('Inactive'), value: 2 },
      { label: i18n.t('Pending'), value: 3 },
		];

    const commissionTypeOptions = [
			{ label: i18n.t('Flexible'), value: 1 },
			{ label: i18n.t('Fixed'), value: 2 },
		];

    const agencyTypeOptions = [
			{ label: i18n.t('Cha'), value: 1 },
			{ label: i18n.t('Con'), value: 2 },
		];

    const searchFilter = () => {
      fetchAgency();
    }

    const resetFilter = () => {
      search.value = {
        username: "",
        email: "",
        phone: "",
        status: "",
        fromDate: "",
        toDate: ""
      };
    };

    const {
      search,
      fetchAgency,
      listAgency,
      tableColumns,
      perPage,
      currentPage,
      totalAgency,
      dataMeta,
      refAgencyListTable,
      refetchData,
      resolveStatus,
    } = useAgencyList();

    return {
      search,
      fetchAgency,
      listAgency,
      tableColumns,
      perPage,
      currentPage,
      totalAgency,
      dataMeta,
      refAgencyListTable,
      refetchData,
      resolveStatus,
      numberFormat,
      formatDateTime,
      baseUrl,
      search,
      statusOptions,
      commissionTypeOptions,
      agencyTypeOptions,
      searchFilter,
      resetFilter
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
